import React from "react";

function Contact() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        fill="#808080"
        d="M2 16c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 010 14V2C0 1.45.196.98.588.587A1.926 1.926 0 012 0h16c.55 0 1.02.196 1.413.588C19.803.979 20 1.45 20 2v12c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0118 16H2zM18 4l-7.475 4.675a1.04 1.04 0 01-.525.15 1.04 1.04 0 01-.525-.15L2 4v10h16V4zm-8 3l8-5H2l8 5zM2 4.25V2.775 2.8v-.012V4.25z"
      ></path>
    </svg>
  );
}

export default Contact;
