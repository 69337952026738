import React from "react";

function DataScienceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path
        fill="#A4C639"
        d="M24 2.5c-7.098 0-12.923 5.825-12.923 12.923v1.846H9.231a3.703 3.703 0 00-3.692 3.693v22.153a3.703 3.703 0 003.692 3.693h29.538a3.703 3.703 0 003.693-3.693V20.961a3.703 3.703 0 00-3.693-3.692h-1.846v-1.846C36.923 8.325 31.1 2.5 24 2.5zm0 3.692c5.086 0 9.23 4.145 9.23 9.231v1.846H14.77v-1.846c0-5.086 4.144-9.23 9.23-9.23zm0 16.616c5.17 0 9.23 4.061 9.23 9.23 0 5.17-4.06 9.231-9.23 9.231-5.17 0-9.23-4.061-9.23-9.23 0-5.17 4.06-9.231 9.23-9.231zm0 3.692c-3.138 0-5.538 2.4-5.538 5.538 0 3.139 2.4 5.539 5.538 5.539 3.139 0 5.539-2.4 5.539-5.538 0-.554-.174-1.12-.174-1.673-.554.923-1.488 1.672-2.596 1.672-1.477 0-2.769-1.292-2.769-2.769 0-1.107.75-2.042 1.673-2.595-.554 0-1.119-.174-1.673-.174z"
      ></path>
    </svg>
  );
}

export default DataScienceIcon;
