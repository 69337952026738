import React from "react";

function Testimonials() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      fill="none"
      viewBox="0 0 22 16"
    >
      <path
        fill="#999"
        fillRule="evenodd"
        d="M.455 2.047s0-.66.466-1.126c0 0 .466-.466 1.126-.466h17.906s.66 0 1.126.466c0 0 .466.466.466 1.126v11.906s0 .66-.466 1.126c0 0-.466.466-1.126.466H2.047s-.66 0-1.126-.466c0 0-.466-.466-.466-1.126V2.047zm1.59 0v11.906l.002.002h17.907V2.045H2.045z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#999"
        d="M4.25 8.75h13.5a.75.75 0 000-1.5H4.25a.75.75 0 000 1.5zM4.25 5.75h13.5a.75.75 0 000-1.5H4.25a.75.75 0 000 1.5zM3.5 11c0 .414.336.75.75.75H5a.75.75 0 000-1.5h-.75a.75.75 0 00-.75.75zM8 11.75h6a.75.75 0 000-1.5H8a.75.75 0 000 1.5zM16.25 11c0 .414.336.75.75.75h.75a.75.75 0 000-1.5H17a.75.75 0 00-.75.75z"
      ></path>
    </svg>
  );
}

export default Testimonials;
