import React from "react";

function About() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#999"
        stroke="#999"
        strokeWidth="0.2"
        d="M11.945 13.1H12.12v0h.004A3.588 3.588 0 0015.6 9.505v0A3.609 3.609 0 0012 5.9a3.609 3.609 0 00-3.6 3.604c0 1.95 1.52 3.533 3.545 3.596zM9.894 9.496c0-1.154.937-2.1 2.097-2.1a2.101 2.101 0 01.115 4.2 1.347 1.347 0 00-.18-.005.286.286 0 00-.031.004 2.09 2.09 0 01-2.001-2.1z"
      ></path>
      <path
        fill="#999"
        d="M11.999 22c-2.513 0-4.913-.869-6.772-2.45a.632.632 0 01-.224-.548c.121-1.034.812-1.999 1.961-2.711 2.784-1.721 7.295-1.721 10.07 0 1.148.72 1.84 1.677 1.96 2.71a.6.6 0 01-.224.548C16.912 21.131 14.511 22 12 22zm-5.53-3.172c1.55 1.208 3.503 1.869 5.53 1.869s3.979-.661 5.53-1.869c-.169-.53-.617-1.043-1.28-1.46-2.298-1.425-6.193-1.425-8.51 0-.663.417-1.102.93-1.27 1.46z"
      ></path>
      <path
        fill="#999"
        stroke="#999"
        strokeWidth="0.2"
        d="M1.9 12c0 5.572 4.528 10.1 10.1 10.1S22.1 17.571 22.1 12c0-5.572-4.529-10.1-10.1-10.1C6.428 1.9 1.9 6.428 1.9 12zm1.595 0c0-4.689 3.816-8.505 8.505-8.505 4.689 0 8.505 3.816 8.505 8.505 0 4.689-3.816 8.505-8.505 8.505-4.689 0-8.505-3.816-8.505-8.505z"
      ></path>
    </svg>
  );
}

export default About;
