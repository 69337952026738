import React from "react";

function E5() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="30"
      fill="none"
      viewBox="0 0 27 30"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.748.668a1.5 1.5 0 01-.416 2.08L4.265 7.46 13.5 13.23l9.235-5.771-7.067-4.712A1.5 1.5 0 0117.332.252l9 6A1.5 1.5 0 0127 7.5V18a1.5 1.5 0 01-3 0v-7.793l-9 5.624v9.866l6.668-4.445a1.5 1.5 0 011.664 2.496l-9 6a1.5 1.5 0 01-1.664 0l-9-6a1.5 1.5 0 111.664-2.496L12 25.697v-9.865l-9-5.626V18a1.5 1.5 0 01-3 0V7.5C0 7 .25 6.53.668 6.252l9-6a1.5 1.5 0 012.08.416z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default E5;
