import React from "react";

function Work() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#999"
        d="M16.326 12.767V5.233A2.729 2.729 0 0018 2.72 2.726 2.726 0 0015.28 0a2.738 2.738 0 00-2.513 1.674H5.233A2.729 2.729 0 002.72 0 2.726 2.726 0 000 2.72c0 1.106.678 2.094 1.674 2.513v7.534A2.729 2.729 0 000 15.28 2.726 2.726 0 002.72 18a2.737 2.737 0 002.513-1.674h7.534A2.729 2.729 0 0015.28 18 2.726 2.726 0 0018 15.28a2.738 2.738 0 00-1.674-2.513zm-1.332-.192c-.092.008-.175.025-.268.042a2.33 2.33 0 00-.653.226c-.05.025-.1.05-.15.084a2.59 2.59 0 00-.996.996c-.034.05-.06.1-.084.15a2.33 2.33 0 00-.226.653c-.017.093-.034.176-.042.268-.008.026-.008.05-.008.076H5.432a2.846 2.846 0 00-.117-.603 1.05 1.05 0 00-.075-.21c-.025-.058-.05-.117-.084-.184-.025-.05-.05-.1-.084-.15a2.59 2.59 0 00-.996-.996c-.05-.034-.1-.06-.15-.084a3.259 3.259 0 00-.185-.084 1.053 1.053 0 00-.209-.075 2.85 2.85 0 00-.603-.117V5.432c.218-.016.419-.058.62-.125.075-.017.159-.05.234-.084.042-.017.092-.042.143-.067.05-.025.1-.05.15-.084a2.59 2.59 0 00.996-.996c.034-.05.06-.1.084-.15.025-.05.05-.101.067-.143.034-.075.067-.159.084-.234.067-.201.109-.402.125-.62h7.133c.017.226.06.436.134.645.017.067.042.142.076.21.017.05.042.091.067.142.025.05.05.1.084.15.234.419.577.762.996.996.05.034.1.06.15.084.05.025.093.05.143.067.067.034.142.059.21.076.208.075.418.117.644.133v7.133c-.025 0-.05 0-.076.009z"
      ></path>
    </svg>
  );
}

export default Work;
