import profilePic from "./assets/Images/pic_mmm.png";

import React from "react";

const About = () => {
  const handleClick = () => {
    // Smooth scroll to the contact section
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className="p-5 md:p-10 lg:p-14 space-y-5 border-t border-[#292929]"
      id="about"
    >
      <p className="text-white text-3xl sm:text-5xl 3xl:text-6xl mt-10">
        About Mujahid
      </p>
      <p className="text-white 3xl:text-xl">
        I'm a passionate and innovative back-end developer with a flair for
        creating exceptional user experiences and a knack for turning ideas into
        reality using the power of no-code solutions.
      </p>
      <div className="lg:flex gap-x-5">
        <img
          src={profilePic}
          alt="Home Icon"
          width={512}
          height={512}
          className="w-1/2 sm:w-1/3 rounded-lg"
        />
        <div className="text-[#808080] 2xl:text-xl 3xl:text-2xl space-y-5 mt-5 lg:mt-0">
          <p>
            As a dedicated and experienced backend developer, I, Mujahid Munir,
            bring over six years of expertise in server-side logic, database
            management, and API integration.
          </p>
          <p>
            Throughout my career, I've led and contributed to a diverse range of
            projects, from small startups to large-scale enterprise
            applications. My role as the Director of Cognitive Storage Solution
            and CEO of SparkAi has sharpened my leadership skills, allowing me
            to drive innovation and deliver high-performance solutions in
            dynamic environments.
          </p>
          <p>
            I am passionate about continuous learning and staying updated with
            the latest backend technologies. My ability to communicate complex
            technical concepts to non-technical stakeholders ensures project
            alignment with business goals. By fostering a collaborative work
            environment, I inspire teams to achieve excellence and push the
            boundaries of backend development.
          </p>
        </div>
      </div>
      <div className="hover:cursor-pointer mt-5 text-wrap:balance bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
        <button
          onClick={handleClick}
          className="relative w-full py-3 border border-[#292929] bg-transparent hover:bg-white/5 text-white rounded overflow-hidden group"
        >
          <span className="block leading-tight transition-all duration-500 ease-in-out transform group-hover:-translate-y-full group-hover:opacity-0">
            Let's Talk
          </span>
          <span className="absolute inset-0 flex items-center justify-center transition-all duration-500 ease-in-out transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 3xl:text-xl">
            Let's Talk
          </span>
        </button>
      </div>
    </div>
  );
};

export default About;
