import React, { useState } from "react";
import F1 from "./assets/Images/F1.png";
import F2 from "./assets/Images/F2.png";
import F3 from "./assets/Images/F3.png";
import F4 from "./assets/Images/f4.png";
import F5 from "./assets/Images/f5.png";
import F6 from "./assets/Images/f6.png";

const featuredImages = [
  {
    id: 1,
    href: "link-to-linkedin-profile",
    src: F1,
    alt: "LinkedIn Icon",
    description: "Top 20 Videos & Shorts",
  },
  {
    id: 2,
    href: "link-to-other-profile",
    src: F2,
    alt: "Other Icon",
    description: "Top 20 Videos & Shorts",
  },
  {
    id: 3,
    href: "link-to-instagram-profile",
    src: F3,
    alt: "Instagram Icon",
    description: "Prompt Privacy - Admin & User",
  },
  {
    id: 4,
    href: "link-to-instagram-profile",
    src: F4,
    alt: "Instagram Icon",
    description: "Overpower - Cyber-Risk MGMT",
  },
  {
    id: 5,
    href: "link-to-instagram-profile",
    src: F5,
    alt: "Instagram Icon",
    description: "Ethera Web - Mobile App",
  },
  {
    id: 6,
    href: "link-to-instagram-profile",
    src: F6,
    alt: "Instagram Icon",
    description: "Healthonomy - Panel",
  },
];

const Work = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const imagesToShow = showAll ? featuredImages : featuredImages.slice(0, 3);

  return (
    <div className="p-5 md:p-10 lg:p-14 text-white bg-background" id="work" >
      <p className="text-3xl sm:text-4xl mt-10">Featured Work</p>
      <div className="grid sm:grid-cols-2 xl:grid-cols-3 group-hover:text-[#FBA310] gap-x-10 gap-y-14 lg:gap-y-20 mt-12  max-w-full">
        {imagesToShow.map((item) => (
          <div key={item.id} className="group">
            <img
              src={item.src}
              alt={item.alt}
              width={460}
              height={320}
              className="rounded-xl transform transition-transform duration-300 hover:cursor-pointer hover:scale-110"
            />
            <div>
              <p className="mt-6 text-sm sm:text-base text-[#808080]">
                Top Videos & Shorts
              </p>
              <p className="text-white text-lg sm:text-2xl group-hover:text-[#FBA310] hover:cursor-pointer">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="hover:cursor-pointer mt-5 md:mt-10 md:mb-3 text-wrap:balance bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
        <button
          onClick={toggleShowAll}
          className="relative w-full my-5 sm:my-0 py-3 border border-[#292929] bg-transparent hover:bg-white/5 text-white rounded overflow-hidden group"
        >
          <span className="block leading-tight transition-all duration-500 ease-in-out transform group-hover:-translate-y-full group-hover:opacity-0">
            {showAll ? "View Less" : "View All Work"}
          </span>
          <span className="absolute inset-0 flex items-center justify-center transition-all duration-500 ease-in-out transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100">
            {showAll ? "View Less" : "View All Work"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Work;
