import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="32"
      fill="none"
      viewBox="0 0 30 32"
    >
      <path
        fill="#fff"
        d="M9.684 2.53a2.608 2.608 0 00-2.816.718 2.585 2.585 0 00-.54 1.669v2.087c0 1.49.141 3.04-.122 4.514a5.546 5.546 0 01-3.473 4.243c.103-.036.502.225.594.274.262.142.513.303.752.482C5.665 17.73 6.143 19.9 6.32 21.792a27.124 27.124 0 010 4.01c-.056.99 0 2.143.811 2.836.676.573 1.731.66 2.549.389v2.535c-1.342.086-2.77.159-3.957-.484-2.11-1.138-2.15-3.529-2.146-5.632 0-1.372 0-2.747-.1-4.115-.078-1.115-.167-2.292-.989-3.14A3.713 3.713 0 000 17.147v-2.74a3.455 3.455 0 002.638-1.2 3.297 3.297 0 00.719-2.084l.217-6.7A4.82 4.82 0 014.68 1.454 4.178 4.178 0 017.87 0h1.813v2.53zM20.326 2.53a2.605 2.605 0 012.816.718c.372.475.563 1.066.54 1.668v2.088c0 1.49-.141 3.04.122 4.514a5.553 5.553 0 003.463 4.243c-.1-.036-.498.225-.59.274-.26.142-.51.303-.746.481-1.59 1.214-2.064 3.384-2.242 5.276-.1 1.335-.1 2.675 0 4.01.053.989 0 2.143-.814 2.835-.66.574-1.728.66-2.55.39v2.535c1.346.086 2.77.159 3.957-.485 2.11-1.137 2.15-3.528 2.15-5.631 0-1.372 0-2.747.096-4.115.082-1.115.171-2.292.99-3.14A3.713 3.713 0 0130 17.147v-2.74a3.458 3.458 0 01-2.638-1.2 3.297 3.297 0 01-.719-2.084l-.214-6.7a4.841 4.841 0 00-1.104-2.968A4.188 4.188 0 0022.128 0h-1.803v2.53zM11.145 16.562H9.786V6.022h2.311c.488 0 .971.105 1.422.31.45.205.86.506 1.205.886.345.38.618.831.804 1.328.186.496.282 1.028.28 1.566.002.537-.094 1.07-.28 1.567a4.14 4.14 0 01-.804 1.33c-.345.38-.754.681-1.205.887-.45.206-.934.313-1.422.313h-.952v2.353zm0-3.858h.96c.627 0 1.23-.275 1.673-.764.444-.489.693-1.152.693-1.844a2.759 2.759 0 00-.697-1.838 2.272 2.272 0 00-1.67-.766h-.96v5.212z"
      ></path>
      <path
        fill="#fff"
        d="M17.235 17.894v4.55h3.547v1.34h-3.547v2.564H15.81v-9.786h6.022v1.332h-4.596z"
      ></path>
    </svg>
  );
}

export default Icon;
