import React from "react";

function E2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      fill="none"
      viewBox="0 0 32 30"
    >
      <path
        fill="#fff"
        d="M13.822 0L6.655 17.328a1.248 1.248 0 001.153 1.724H14.7V30C6.581 30 0 23.278 0 14.987 0 6.997 6.112.464 13.822 0zM16.577.095v11.138h7.181c.895 0 1.499.914 1.148 1.737l-7.129 16.7a14.458 14.458 0 005.055-2.174v1.878h8.758V.599h-8.758v1.879A14.428 14.428 0 0016.577.095z"
      ></path>
    </svg>
  );
}

export default E2;
