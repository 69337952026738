import React from "react";

function Experience() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#999"
        d="M5 14c.283 0 .52-.096.713-.287A.968.968 0 006 13V8a.968.968 0 00-.287-.713A.968.968 0 005 7a.968.968 0 00-.713.287A.968.968 0 004 8v5c0 .283.096.52.287.713.192.191.43.287.713.287zm4 0c.283 0 .52-.096.713-.287A.968.968 0 0010 13V5a.968.968 0 00-.287-.713A.968.968 0 009 4a.968.968 0 00-.713.287A.968.968 0 008 5v8c0 .283.096.52.287.713.192.191.43.287.713.287zm4 0c.283 0 .52-.096.713-.287A.968.968 0 0014 13v-2a.968.968 0 00-.287-.713A.968.968 0 0013 10a.968.968 0 00-.713.287A.968.968 0 0012 11v2c0 .283.096.52.287.713.192.191.43.287.713.287zM2 18c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 010 16V2C0 1.45.196.98.588.587A1.926 1.926 0 012 0h14c.55 0 1.02.196 1.413.588C17.803.979 18 1.45 18 2v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0116 18H2zm0-2h14V2H2v14z"
      ></path>
    </svg>
  );
}

export default Experience;
