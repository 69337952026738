import React from "react";

function LinkedIn(className) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
      className={className}
    >
      <path
        fill="#999"
        fillRule="evenodd"
        d="M.5 2.5s0-.828.586-1.414c0 0 .586-.586 1.414-.586h21s.828 0 1.414.586c0 0 .586.586.586 1.414v21s0 .828-.586 1.414c0 0-.586.586-1.414.586h-21s-.828 0-1.414-.586c0 0-.586-.586-.586-1.414v-21zm2 0v21h21v-21h-21z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#999"
        d="M11 11v8a1 1 0 102 0v-8a1 1 0 10-2 0zM7 11v8a1 1 0 102 0v-8a1 1 0 10-2 0z"
      ></path>
      <path
        fill="#999"
        d="M18 14.5V19a1 1 0 102 0v-4.5c0-1.864-1.318-3.182-1.318-3.182C17.364 10 15.5 10 15.5 10c-1.864 0-3.182 1.318-3.182 1.318C11 12.636 11 14.5 11 14.5a1 1 0 102 0c0-1.036.732-1.768.732-1.768C14.464 12 15.5 12 15.5 12c1.035 0 1.768.732 1.768.732C18 13.464 18 14.5 18 14.5zM9.5 7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      ></path>
    </svg>
  );
}

export default LinkedIn;
