import React from "react";

function E1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#fff"
        d="M0 0v30l7.5-7.5v-15h15V15H15l-7.5 7.5H30V0H0z"
      ></path>
    </svg>
  );
}

export default E1;
