// import logo from "./logo.svg";
import "./App.css";
import Home from "./Components/Home";
import Layout from "./providers/layout";
import Work from "./Components/Work";
import Testimonials from "./Components/Testimonials";
import Experience from "./Components/Experience";
import Projects from "./Components/Projects";
import Skills from "./Components/Skills";
import About from "./Components/About";
import Contact from "./Components/Contact";

function App() {
  return (
    <>
      <div className="no-scrollbar">
        <Layout>
          <Home />
          <Work />
          <Testimonials />
          <Experience />
          <Projects />
          <Skills />
          <About />
          <Contact />
        </Layout>
      </div>
    </>
  );
}

export default App;
