import React from "react";

function Skills() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
    >
      <path
        fill="#999"
        d="M2 18c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 010 16V2C0 1.45.196.98.588.587A1.926 1.926 0 012 0h16c.55 0 1.02.196 1.413.588C19.803.979 20 1.45 20 2v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0118 18H2zm0-2h16V2H2v14zm5-2c.283 0 .52-.096.713-.287A.968.968 0 008 13a.968.968 0 00-.287-.713A.967.967 0 007 12H4a.967.967 0 00-.712.287A.968.968 0 003 13c0 .283.096.52.288.713.191.191.429.287.712.287h3zm5.55-4.825l-.725-.725a.916.916 0 00-.7-.287c-.267.008-.5.112-.7.312-.183.2-.28.433-.288.7-.008.267.088.5.288.7L11.85 11.3c.2.2.433.3.7.3.267 0 .5-.1.7-.3l3.55-3.55c.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7-.2-.2-.438-.3-.713-.3-.274 0-.512.1-.712.3L12.55 9.175zM7 10c.283 0 .52-.096.713-.287A.968.968 0 008 9a.968.968 0 00-.287-.713A.968.968 0 007 8H4a.968.968 0 00-.712.287A.968.968 0 003 9c0 .283.096.52.288.713.191.191.429.287.712.287h3zm0-4c.283 0 .52-.096.713-.287A.968.968 0 008 5a.968.968 0 00-.287-.713A.968.968 0 007 4H4a.968.968 0 00-.712.287A.968.968 0 003 5c0 .283.096.52.288.713.191.191.429.287.712.287h3z"
      ></path>
    </svg>
  );
}

export default Skills;
