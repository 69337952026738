import F1 from "./assets/Images/F1.png";
import F2 from "./assets/Images/F2.png";
import F3 from "./assets/Images/F3.png";
import F4 from "./assets/Images/f4.png";
import F5 from "./assets/Images/f5.png";
import F6 from "./assets/Images/f6.png";

const project = [
  {
    id: 1,
    href: "link-to-linkedin-profile",
    src: F1,
    alt: "LinkedIn Icon",
    description: "Top 20 Videos & Shorts",
  },
  {
    id: 2,
    href: "link-to-other-profile",
    src: F2,
    alt: "Other Icon",
    description: "Top 20 Videos & Shorts",
  },
  {
    id: 3,
    href: "link-to-instagram-profile",
    src: F3,
    alt: "Instagram Icon",
    description: "Prompt Privacy - Admin & User",
  },
  {
    id: 4,
    href: "link-to-instagram-profile",
    src: F4,
    alt: "Instagram Icon",
    description: "Overpower - Cyber-Risk MGMT",
  },
  {
    id: 5,
    href: "link-to-instagram-profile",
    src: F5,
    alt: "Instagram Icon",
    description: "Ethera Web - Mobile App",
  },
  {
    id: 6,
    href: "link-to-instagram-profile",
    src: F6,
    alt: "Instagram Icon",
    description: "Healthonomy - Panel",
  },
];
const Projects = () => {
  return (
    <div
      className="p-5 md:p-10 lg:p-14 border-t border-[#292929] text-white bg-background"
      id="projects"
    >
      <p className="text-3xl sm:text-4xl mt-10">My Projects</p>
      <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-x-auto sm:gap-x-10 gap-y-8 sm:gap-y-20 mt-8 sm:mt-12  max-w-full ">
        {project.map((item) => (
          <div key={item.id} className="group">
            <img
              src={item.src}
              alt={item.alt}
              width={512}
              height={512}
              className="w-[350px] h-[250px] lg:w-[460px] lg:h-[320px] rounded-xl transform transition-transform duration-300 hover:cursor-pointer hover:scale-110"
            />
            <div>
              <p className="mt-6 text-sm sm:text-base text-[#808080]">
                Top Videos & Shorts
              </p>
              <p className="text-white text-lg sm:text-2xl group-hover:text-[#FBA310]">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Projects;
