import React from "react";

function ProblemSolvingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path
        fill="#FFC331"
        d="M24 2.5l-18 8v12c0 11.1 7.68 21.48 18 24 10.32-2.52 18-12.9 18-24v-12l-18-8zm14 20c0 3.7-1.02 7.3-2.76 10.42l-2.9-2.9a9.988 9.988 0 00-1.28-12.58 10.006 10.006 0 00-14.14 0 10.006 10.006 0 000 14.14 10.012 10.012 0 0012.58 1.28l3.44 3.44c-2.38 2.84-5.46 5.02-8.94 6.08-8.04-2.5-14-10.84-14-19.88v-9.4l14-6.22 14 6.22v9.4zm-14 8c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6z"
      ></path>
    </svg>
  );
}

export default ProblemSolvingIcon;
