import React from "react";

function Home({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
      className={className}
    >
      <path
        fill="#999"
        d="M2.25 17.714h3.375V12c0-.324.108-.595.323-.814a1.08 1.08 0 01.802-.329h4.5c.319 0 .586.11.802.329.215.219.323.49.323.814v5.714h3.375V7.43L9 2.286 2.25 7.429v10.285zm-2.25 0V7.43c0-.362.08-.705.24-1.029.158-.324.379-.59.66-.8L7.65.457A2.146 2.146 0 019 0c.506 0 .956.152 1.35.457L17.1 5.6a2.299 2.299 0 01.9 1.829v10.285c0 .629-.22 1.167-.66 1.615a2.15 2.15 0 01-1.59.671h-4.5a1.08 1.08 0 01-.802-.329 1.115 1.115 0 01-.323-.814v-5.714h-2.25v5.714c0 .324-.108.595-.323.814A1.08 1.08 0 016.75 20h-4.5a2.15 2.15 0 01-1.59-.671A2.219 2.219 0 010 17.714z"
      ></path>
    </svg>
  );
}

export default Home;
