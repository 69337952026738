import { useEffect, useState } from "react";
const Contact = () => {
  const initialValues = {
    userFullName: "",
    email: "",
    // phone: "",
    // subject: "",
    message: "",
  };
  const handleChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    const nameRegex = /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phoneRegex = /^\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    if (!values.userFullName) {
      errors.userFullName = "Username is required";
    } else if (!nameRegex.test(values.userFullName)) {
      errors.userFullName = "This is not a valid Full name";
    }
    if (!values.email) {
      errors.email = "email is required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    // if (!values.phone) {
    //   errors.phone = "phone Number is required";
    // } else if (!phoneRegex.test(values.phone)) {
    //   errors.phone = "This is not a valid phone Number format";
    // }

    if (!values.message) {
      errors.message = "message is required";
    }

    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    console.log("formErrors", formErrors);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setFormValues(initialValues);
      setIsSubmit(false);
    }
  }, [formErrors]);
  return (
    <div className="p-5 md:p-10 lg:p-14 border-t border-[#292929]" id="contact">
      <p className="text-white text-5xl 3xl:6xl">Let's Chat</p>
      <p className="text-[#808080] mt-7 3xl:text-xl">
        If you'd like to talk about a potential project or just say hi, send me
        a message or email me at <br />
        <a href="mailto:mmm@sparkai.ca" className="text-white hover:text-[#FBA310]">
          mmm@sparkai.ca
        
        </a>
      </p>
      <form action="" className="mt-14">
        <div className="grid sm:grid-cols-2 gap-4 ">
          <div className="flex flex-col 3xl:text-2xl">
            <input
              id="userFullName"
              name="userFullName"
              className="h-10 bg-[#181818] text-white focus:outline-none pl-4 rounded-lg"
              type="text"
              placeholder="Name"
              value={formValues.userFullName}
              onChange={handleChange}
            />
            <p className="text-red-400">{formErrors.userFullName}</p>
          </div>
          <div className="flex flex-col 3xl:text-2xl 2xl:text-xl">
            <input
              id="email"
              name="email"
              className="h-10 bg-[#181818] pl-4 rounded-lg  text-white focus:outline-none"
              type="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
            <p className="text-red-400">{formErrors.email}</p>
          </div>
        </div>

        <div className="flex flex-col mt-4 3xl:text-2xl 2xl:text-xl">
          <textarea
            id="message"
            name="message"
            className="h-44 bg-[#181818] text-white pl-4 pt-4 focus:outline-none focus:border-rose-600"
            placeholder="Message"
            value={formValues.message}
            onChange={handleChange}
          ></textarea>
          <p className="text-red-400">{formErrors.message}</p>
        </div>
      </form>
      <button
        onClick={handleSubmit}
        className="w-[100%] text-black font-bold bg-[#FBA310] hover:cursor-pointer hover:bg-[#FBA310]/60 rounded-lg py-3 mt-4 3xl:text-xl"
      >
        Send Message
      </button>
    </div>
  );
};
export default Contact;
