import React from "react";

function PostManIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      fill="none"
      viewBox="0 0 49 49"
    >
      <path
        fill="#FF6C37"
        d="M43.391 9.696a24.221 24.221 0 10-4.36 33.97c10.581-8.178 12.535-23.385 4.36-33.97z"
      ></path>
      <path
        fill="#fff"
        d="M32.39 15.712L22.177 26.006l-2.886-2.967c10.052-10.052 11.06-9.144 13.1-7.327z"
      ></path>
      <path
        fill="#FF6C37"
        d="M22.177 26.287a.363.363 0 01-.242-.1l-2.906-2.907a.364.364 0 010-.505c9.91-9.91 11.262-9.446 13.603-7.326a.323.323 0 01.121.262.284.284 0 01-.1.263L22.44 26.187a.382.382 0 01-.263.1zm-2.382-3.249l2.382 2.382 9.689-9.688a2.18 2.18 0 00-2.867-.485c-1.756.787-4.581 3.19-9.204 7.791z"
      ></path>
      <path
        fill="#fff"
        d="M25.104 28.852l-2.785-2.846 10.213-10.093c2.745 2.685-1.333 7.167-7.428 12.939z"
      ></path>
      <path
        fill="#FF6C37"
        d="M25.104 29.215a.424.424 0 01-.242-.1l-2.846-2.786a.424.424 0 010-.323.391.391 0 010-.263L32.229 15.53a.383.383 0 01.524 0 3.13 3.13 0 011.01 2.402c0 2.725-3.11 6.156-8.397 11.162a.422.422 0 01-.262.121zm-2.26-3.21l2.28 2.28c4.036-3.814 7.892-7.75 7.912-10.415a2.3 2.3 0 00-.525-1.553l-9.668 9.689z"
      ></path>
      <path
        fill="#fff"
        d="M19.371 23.1l2.019 2.018a.12.12 0 01.041.09.121.121 0 01-.041.092l-4.26.908a.403.403 0 01-.362-.686l2.482-2.463s.081 0 .121.04z"
      ></path>
      <path
        fill="#FF6C37"
        d="M17.03 26.611a.787.787 0 01-.666-.383.768.768 0 01.142-.909l2.462-2.482a.484.484 0 01.645 0l2.02 2.018c.119.114.166.286.12.445a.468.468 0 01-.343.323l-4.259.908-.12.08zm2.261-3.067l-2.28 2.28 3.774-.807-1.494-1.473z"
      ></path>
      <path
        fill="#fff"
        d="M37.82 10.04a3.915 3.915 0 00-5.889 5.117 3.917 3.917 0 005.183 1.059l-2.746-2.745 3.452-3.431z"
      ></path>
      <path
        fill="#FF6C37"
        d="M35.096 17.144a4.277 4.277 0 01-4.143-5.107c.161-.823.56-1.58 1.148-2.179a4.322 4.322 0 016.055 0 .343.343 0 01.09.407.348.348 0 01-.09.118l-3.27 3.089 2.483 2.442a.34.34 0 01.1.303.362.362 0 01-.16.262 4.39 4.39 0 01-2.22.666l.007-.001zm0-7.832a3.538 3.538 0 00-3.265 2.195 3.566 3.566 0 00.762 3.86 3.53 3.53 0 003.916.748l-2.382-2.382a.323.323 0 010-.505l3.148-3.17a3.621 3.621 0 00-2.18-.746z"
      ></path>
      <path
        fill="#fff"
        d="M37.88 10.1l-3.45 3.431 2.745 2.745c.276-.158.533-.348.766-.565a4.037 4.037 0 00-.06-5.61z"
      ></path>
      <path
        fill="#FF6C37"
        d="M37.113 16.58a.302.302 0 01-.242-.101l-2.745-2.746a.323.323 0 010-.505l3.43-3.37a.363.363 0 01.606 0 4.24 4.24 0 010 6.055c-.237.252-.51.47-.807.646a.323.323 0 01-.242.02zm-2.22-3.108l2.28 2.28c.158-.11.306-.231.445-.363a3.572 3.572 0 001.05-2.523 3.467 3.467 0 00-.868-2.301l-2.907 2.907z"
      ></path>
      <path
        fill="#fff"
        d="M32.814 16.136a1.475 1.475 0 00-2.018 0l-9.143 9.122 1.514 1.534 9.668-8.477a1.497 1.497 0 00-.02-2.18z"
      ></path>
      <path
        fill="#FF6C37"
        d="M23.085 27.156a.363.363 0 01-.242-.1L21.31 25.52a.364.364 0 010-.504l9.143-9.104a1.857 1.857 0 013.17 1.373 1.9 1.9 0 01-.626 1.332l-9.668 8.477a.345.345 0 01-.242.061zm-1.069-1.898l1.009 1.03 9.486-8.256c.235-.204.375-.497.384-.808a1.084 1.084 0 00-.343-.827 1.13 1.13 0 00-1.595 0l-8.941 8.861z"
      ></path>
      <path
        fill="#fff"
        d="M14.568 35.654a.204.204 0 000 .242l.404 1.756a.521.521 0 01-.252.53.492.492 0 01-.576-.059l-2.765-2.664 8.618-8.687h2.988l2.019 2.019a45.824 45.824 0 01-10.436 6.863z"
      ></path>
      <path
        fill="#FF6C37"
        d="M14.386 38.62a.853.853 0 01-.605-.262l-2.644-2.644a.324.324 0 010-.505l8.679-8.7a.34.34 0 01.181-.1h3.008a.363.363 0 01.242 0l2.018 2.019a.323.323 0 01.101.282.326.326 0 01-.121.263l-.161.141a49.456 49.456 0 01-10.355 6.64l.384 1.636a.87.87 0 01-.827 1.07l.1.16zm-2.462-3.169l2.381 2.402a.121.121 0 00.162 0 .121.121 0 000-.142l-.404-1.756a.564.564 0 01.303-.645 49.617 49.617 0 0010.092-6.5l-1.656-1.655h-2.704l-8.174 8.296z"
      ></path>
      <path
        fill="#fff"
        d="M9.3 37.53l2.019-2.018 3.108 3.108-4.945-.343a.404.404 0 01-.181-.746z"
      ></path>
      <path
        fill="#FF6C37"
        d="M14.487 38.923l-4.925-.343a.78.78 0 01-.647-.494.772.772 0 01.162-.798l2.02-2.019a.363.363 0 01.504 0l3.088 3.088a.342.342 0 010 .404.385.385 0 01-.202.162zm-4.925-1.13l4.036.262-2.22-2.079-1.816 1.817z"
      ></path>
      <path
        fill="#fff"
        d="M23.267 27.015l-4.077.626 2.361-2.34 1.716 1.714z"
      ></path>
      <path
        fill="#FF6C37"
        d="M19.19 28.024a.345.345 0 01-.323-.203.34.34 0 010-.403l2.34-2.361a.383.383 0 01.525 0l1.717 1.715a.34.34 0 010 .363.356.356 0 01-.282.263l-4.037.626h.06zm2.361-2.2l-1.311 1.311 2.26-.343-.949-.969z"
      ></path>
      <path
        fill="#fff"
        d="M23.267 27.014l-2.846.363a.363.363 0 01-.343-.605l1.474-1.474 1.715 1.716z"
      ></path>
      <path
        fill="#FF6C37"
        d="M20.34 27.762a.746.746 0 01-.645-.384.727.727 0 01.12-.868l1.474-1.474a.383.383 0 01.525 0l1.716 1.716a.363.363 0 01-.222.626l-2.825.363-.143.02zm0-.747l2.14-.263-.948-.948-1.211 1.21h.02zM37.9 12.42a.302.302 0 00-.383-.12.28.28 0 00-.141.166.285.285 0 00.02.217.868.868 0 01-.101.908.257.257 0 00-.076.192c0 .07.028.14.076.191.052.02.11.02.161 0a.265.265 0 00.263-.08 1.392 1.392 0 00.181-1.473z"
      ></path>
    </svg>
  );
}

export default PostManIcon;
