import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Home from "../SideBarIcons/Home";
import Work from "../SideBarIcons/Work";
import Testimonials from "../SideBarIcons/Testimonials";
import Experience from "../SideBarIcons/Experience";
import Projects from "../SideBarIcons/Projects";
import Skills from "../SideBarIcons/Skills";
import About from "../SideBarIcons/About";
import Contact from "../SideBarIcons/Contact";


const Layout = ({ children }) => {
  
  const [openNav, setOpenNav] = useState(false);
  const handleMenu = () => {
    setOpenNav((prev) => !prev);
  };

  const handleOptionClick = () => {
    setOpenNav(false);
    // Add your logic for handling the selected option here

    
  };
  return (
    <div className="flex flex-row">
      <Navbar />
      <div className="bg-background h-screen overflow-scroll relative">
        <div
          // className="coolboy69 w-full h-[70px] text-white border-2 border-red-500 rounded-lg bg-pink-500 flex items-center justify-end p-8 cursor-pointer "
          className=" w-full h-[90px] text-white rounded-lg bg-[#121212] border-b border-[#292929] flex items-center justify-end cursor-pointer xl:hidden fixed z-10"
        >
          <p className="absolute left-0 ml-5 md:pl-5  lg:p-0 lg:ml-16 text-xl md:text-3xl">
            Mujahid Munir
          </p>
          <div
            className={`border-l border-[#292929] p-11 lg:p-0 flex items-center justify-center h-16 w-16`}
          >
            <div
              className={`MenuBox h-4 w-4 bg-[#121212] p-3 relative transform ${
                openNav ? "rotate-135" : ""
              }`}
          onClick={handleMenu}

            >
              <div className="box1 h-1.5 w-1.5 bg-[#8A8A8A] absolute top-0 left-0"></div>
              <div className="box2 h-1.5 w-1.5 bg-[#8A8A8A] absolute top-0 right-0"></div>
              <div className="box3 h-1.5 w-1.5 bg-[#8A8A8A] absolute bottom-0 left-0"></div>
              <div className="box4 h-1.5 w-1.5 bg-[#8A8A8A] absolute bottom-0 right-0"></div>
            </div>
          </div>
        </div>
        {openNav ? (
          <>
            <ul className=" absolute z-50 bg-[#121212] w-full top-[89.5px] overflow-y-scroll sticky">
              <li className="p-7 md:pl-10 lg:pl-16 border-b border-[#292929]  flex items-center hover:cursor-pointer hover:bg-[#292929]/15 hover:text-white text-white ">
                <Home />

                <a href="#home" className="ml-3 " onClick={handleOptionClick}>
                  Home
                </a>
              </li>
              <li className="p-7 md:pl-10 lg:pl-16 flex border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <Work />

                <a href="#Work" className="cursor-pointer ml-3" onClick={handleOptionClick}>
                  Work
                </a>
              </li>
              <li className="p-7 md:pl-10 lg:pl-16 flex border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <Testimonials />
                <a href="#testimonials" className="cursor-pointer ml-3" onClick={handleOptionClick}>
                  Testimonials
                </a>
              </li>
              <li className="p-7 md:pl-10 lg:pl-16 flex border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <Experience />
                <a href="#experience" className="cursor-pointer ml-3" onClick={handleOptionClick}>
                  Experience
                </a>
              </li>
              <li className="p-7 md:pl-10 lg:pl-16 flex border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <Projects />
                <a href="#projects" className="cursor-pointer ml-3" onClick={handleOptionClick}>
                  My Projects
                </a>
              </li>
              <li className="p-7 md:pl-10 lg:pl-16 flex border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <Skills />
                <a href="#skills" className="cursor-pointer ml-3" onClick={handleOptionClick}>
                  Skills
                </a>
              </li>
              <li className="p-7 md:pl-10 lg:pl-16 flex border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <About />
                <a href="#about" className="cursor-pointer ml-3" onClick={handleOptionClick}>
                  About
                </a>
              </li>
              <li className="flex md:pl-10 mb-8  p-7 lg:pl-16 border-b border-[#292929] items-center hover:cursor-pointer hover:bg-[#292929]/15 text-white">
                <Contact />
                <a
                  href="#contact"
                  className="cursor-pointer ml-3 flex justify-center text-[#FBA310]"
                  onClick={handleOptionClick}
                >
                  Contact
                </a>
              </li>
            </ul>
          </>
        ) : (
          ""
        )}

        {children}
      </div>
    </div>
  );
};

export default Layout;
