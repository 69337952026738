import React from "react";

function AI() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path
        fill="#fff"
        d="M6.91 32.053c-1.781 0-3.294-.63-4.54-1.888C1.122 28.904.5 27.377.5 25.578c0-1.798.623-3.327 1.87-4.586 1.246-1.258 2.759-1.888 4.54-1.888V14.79c0-1.186.417-2.202 1.254-3.048.837-.845 1.843-1.267 3.018-1.267h6.409c0-1.799.623-3.327 1.87-4.586C20.705 4.63 22.22 4 24 4c1.78 0 3.294.63 4.54 1.888 1.246 1.259 1.87 2.787 1.87 4.586h6.408c1.175 0 2.181.422 3.018 1.267.837.846 1.255 1.862 1.255 3.049v4.315c1.78 0 3.294.63 4.54 1.888 1.246 1.26 1.869 2.788 1.869 4.586 0 1.798-.623 3.327-1.87 4.585-1.245 1.26-2.759 1.889-4.54 1.889v8.631c0 1.187-.418 2.203-1.254 3.048-.837.845-1.843 1.268-3.018 1.268H11.182c-1.175 0-2.181-.423-3.018-1.268-.837-.845-1.255-1.86-1.255-3.048v-8.631zm10.68-4.316c.891 0 1.648-.315 2.27-.944.624-.63.936-1.394.936-2.293 0-.9-.312-1.663-.935-2.293a3.075 3.075 0 00-2.27-.944c-.89 0-1.647.315-2.27.944-.623.63-.935 1.394-.935 2.293 0 .9.312 1.663.935 2.293.623.63 1.38.944 2.27.944zm12.82 0c.89 0 1.646-.315 2.269-.944.623-.63.935-1.394.935-2.293 0-.9-.312-1.663-.935-2.293a3.075 3.075 0 00-2.27-.944c-.89 0-1.647.315-2.27.944-.623.63-.934 1.394-.934 2.293 0 .9.311 1.663.934 2.293.623.63 1.38.944 2.27.944zm-12.82 8.631h12.82c.604 0 1.112-.206 1.521-.62.41-.414.614-.926.614-1.537 0-.612-.204-1.124-.614-1.538-.41-.414-.917-.62-1.522-.62H17.591c-.605 0-1.113.206-1.522.62-.41.414-.614.926-.614 1.538 0 .61.204 1.123.614 1.537.41.414.917.62 1.522.62zm-6.408 4.316h25.636V14.79H11.182v25.894z"
      ></path>
      <path
        fill="url(#paint0_linear_3851_241)"
        d="M6.91 32.053c-1.781 0-3.294-.63-4.54-1.888C1.122 28.904.5 27.377.5 25.578c0-1.798.623-3.327 1.87-4.586 1.246-1.258 2.759-1.888 4.54-1.888V14.79c0-1.186.417-2.202 1.254-3.048.837-.845 1.843-1.267 3.018-1.267h6.409c0-1.799.623-3.327 1.87-4.586C20.705 4.63 22.22 4 24 4c1.78 0 3.294.63 4.54 1.888 1.246 1.259 1.87 2.787 1.87 4.586h6.408c1.175 0 2.181.422 3.018 1.267.837.846 1.255 1.862 1.255 3.049v4.315c1.78 0 3.294.63 4.54 1.888 1.246 1.26 1.869 2.788 1.869 4.586 0 1.798-.623 3.327-1.87 4.585-1.245 1.26-2.759 1.889-4.54 1.889v8.631c0 1.187-.418 2.203-1.254 3.048-.837.845-1.843 1.268-3.018 1.268H11.182c-1.175 0-2.181-.423-3.018-1.268-.837-.845-1.255-1.86-1.255-3.048v-8.631zm10.68-4.316c.891 0 1.648-.315 2.27-.944.624-.63.936-1.394.936-2.293 0-.9-.312-1.663-.935-2.293a3.075 3.075 0 00-2.27-.944c-.89 0-1.647.315-2.27.944-.623.63-.935 1.394-.935 2.293 0 .9.312 1.663.935 2.293.623.63 1.38.944 2.27.944zm12.82 0c.89 0 1.646-.315 2.269-.944.623-.63.935-1.394.935-2.293 0-.9-.312-1.663-.935-2.293a3.075 3.075 0 00-2.27-.944c-.89 0-1.647.315-2.27.944-.623.63-.934 1.394-.934 2.293 0 .9.311 1.663.934 2.293.623.63 1.38.944 2.27.944zm-12.82 8.631h12.82c.604 0 1.112-.206 1.521-.62.41-.414.614-.926.614-1.537 0-.612-.204-1.124-.614-1.538-.41-.414-.917-.62-1.522-.62H17.591c-.605 0-1.113.206-1.522.62-.41.414-.614.926-.614 1.538 0 .61.204 1.123.614 1.537.41.414.917.62 1.522.62zm-6.408 4.316h25.636V14.79H11.182v25.894z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3851_241"
          x1="0.5"
          x2="47.737"
          y1="4.401"
          y2="4.678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC5655"></stop>
          <stop offset="0.25" stopColor="#F7944A"></stop>
          <stop offset="0.5" stopColor="#F9A96E"></stop>
          <stop offset="0.75" stopColor="#F08AAD"></stop>
          <stop offset="1" stopColor="#C0A8CB"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AI;
