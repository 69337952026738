import React from "react";

function E4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="30"
      fill="none"
      viewBox="0 0 29 30"
    >
      <path
        fill="#fff"
        d="M19.027 13.54h-6.324l-1.217-2.108-2.27 5.433h11.838L28.514 30H14.404v-4.054h5.838l-3.162-5.351H.865L11.486 0l7.54 13.54z"
      ></path>
    </svg>
  );
}

export default E4;
