import React from "react";
import john from "./assets/TestimonialsImages/JohnSmith.png";
import Stacy from "./assets/TestimonialsImages/Stacy.png";
import Angelina from "./assets/TestimonialsImages/Angelina.png";
import Jackie from "./assets/TestimonialsImages/Jackie.png";

const testimonialsData = [
  {
    id: 1,
    text: `"Mujahid Munir transformed our backend systems. His expertise in database management and server-side logic greatly improved our application’s performance and reliability."`,
    name: "John Smith",
    position: "CEO AT MINDFUL",
    imgSrc: john,
    imgAlt: "client1",
  },
  {
    id: 2,
    text: `"Working with Mujahid Munir was a fantastic experience. His deep understanding of backend frameworks and dedication to clean, efficient code made a significant impact on our project."`,
    name: "Stacy Sampson",
    position: "LEAD ENGINEER AT LUXE ARCHITECTS",
    imgSrc: Stacy, // Add appropriate image source
    imgAlt: "client2",
  },
  {
    id: 3,
    text: `"Mujahid Munir's backend development skills are top-notch. He was instrumental in optimizing our APIs and ensuring seamless data integration across platforms."`,
    name: "Angelina Corales",
    position: "HEAD OF DESIGN AT PEDALS",
    imgSrc: Angelina, // Add appropriate image source
    imgAlt: "client3",
  },
  {
    id: 4,
    text: `"I highly recommend Mujahid Munir for any backend development needs. His ability to troubleshoot and resolve complex issues quickly was invaluable to our success."`,
    name: "Jackie Johnson",
    position: "STAFF PRODUCT DESIGNER AT NIKE",
    imgSrc: Jackie, // Add appropriate image source
    imgAlt: "client4",
  },
];

const Testimonials = () => {
  return (
    <div
      className="p-5 md:p-10 lg:p-14 border-t border-b border-[#292929]"
      id="testimonials"
    >
      <p className="text-white text-3xl sm:text-4xl mt-3">Testimonials</p>
      <div className="grid sm:grid-cols-2 gap-x-8 mt-3 max-w-full ">
        {testimonialsData.map((testimonial) => (
          <div
            key={testimonial.id}
            className="border border-[#292929] rounded-lg p-5 mt-10"
          >
            <p className="text-white">{testimonial.text}</p>
            <div className="pt-5 flex itmes-center">
              <img
                width={800}
                height={800}
                src={testimonial.imgSrc}
                alt={testimonial.imgAlt}
                className="w-12 h-12 rounded-full"
              />
              <div className="space-y-1 ml-4">
                <p className="text-white">{testimonial.name}</p>
                <p className="text-[#494949] font-bold">
                  {testimonial.position}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
