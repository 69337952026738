import React from "react";

function Youtube20() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="246"
      height="42"
      fill="none"
      viewBox="0 0 246 42"
    >
      <path
        fill="#999"
        d="M212.501 0c-.77 0-1.54.222-2.2.666-1.324.888-2.204 2.224-2.204 3.778v33.112c0 1.554.88 3.112 2.204 3.778.66.444 1.54.666 2.205.666.664 0 1.539-.222 2.204-.666l28.41-16.67c1.32-.887 2.204-2.224 2.204-3.777 0-1.554-.659-2.89-1.984-3.556L214.705.666A3.945 3.945 0 00212.501 0z"
      ></path>
      <path
        fill="#fff"
        d="M183.047 41.257V11.405h6.752c.776 0 1.319.534 1.319 1.396v4.23c0 .369.233.615.582.615.388 0 .621-.246.621-.616v-4.147c0-.903.465-1.478 1.202-1.478h.544c7.76 0 8.303 7.185 8.303 10.347 0 3.737-.737 10.964-11.446 10.964H189.1v8.54h-6.053zm10.088-28.374v4.147c0 .903-.582 1.478-1.435 1.478-.815 0-1.397-.575-1.397-1.478v-3.9c0-.535-.31-.904-.854-.904h-.349v19.627h1.281c5.121 0 5.742-5.625 5.742-10.224 0-3.778-.427-9.403-2.6-9.403h-.038c-.194 0-.35.287-.35.657z"
      ></path>
      <path
        fill="url(#paint0_linear_3745_102)"
        d="M183.047 41.257V11.405h6.752c.776 0 1.319.534 1.319 1.396v4.23c0 .369.233.615.582.615.388 0 .621-.246.621-.616v-4.147c0-.903.465-1.478 1.202-1.478h.544c7.76 0 8.303 7.185 8.303 10.347 0 3.737-.737 10.964-11.446 10.964H189.1v8.54h-6.053zm10.088-28.374v4.147c0 .903-.582 1.478-1.435 1.478-.815 0-1.397-.575-1.397-1.478v-3.9c0-.535-.31-.904-.854-.904h-.349v19.627h1.281c5.121 0 5.742-5.625 5.742-10.224 0-3.778-.427-9.403-2.6-9.403h-.038c-.194 0-.35.287-.35.657z"
      ></path>
      <path
        fill="url(#paint1_linear_3745_102)"
        d="M183.047 41.257V11.405h6.752c.776 0 1.319.534 1.319 1.396v4.23c0 .369.233.615.582.615.388 0 .621-.246.621-.616v-4.147c0-.903.465-1.478 1.202-1.478h.544c7.76 0 8.303 7.185 8.303 10.347 0 3.737-.737 10.964-11.446 10.964H189.1v8.54h-6.053zm10.088-28.374v4.147c0 .903-.582 1.478-1.435 1.478-.815 0-1.397-.575-1.397-1.478v-3.9c0-.535-.31-.904-.854-.904h-.349v19.627h1.281c5.121 0 5.742-5.625 5.742-10.224 0-3.778-.427-9.403-2.6-9.403h-.038c-.194 0-.35.287-.35.657z"
      ></path>
      <path
        fill="#fff"
        d="M160.376 22.121c0-6.323 4.074-11.168 10.476-11.168 6.208 0 10.399 4.475 10.399 10.922 0 5.708-3.299 11.251-6.985 11.251-3.453 0-3.336-4.886-3.996-4.886-.698 0 1.824 4.763-2.212 4.763-4.112 0-7.682-4.887-7.682-10.882zm6.247-.04c0 6.774 1.397 9.608 2.444 9.608 1.436 0-.582-5.05 1.009-5.05 1.591 0 1.319 4.68 2.91 4.68 1.203 0 1.901-2.71 1.901-8.13 0-8.828-1.823-11.374-4.113-11.374-2.328 0-4.151 2.546-4.151 10.265z"
      ></path>
      <path
        fill="url(#paint2_linear_3745_102)"
        d="M160.376 22.121c0-6.323 4.074-11.168 10.476-11.168 6.208 0 10.399 4.475 10.399 10.922 0 5.708-3.299 11.251-6.985 11.251-3.453 0-3.336-4.886-3.996-4.886-.698 0 1.824 4.763-2.212 4.763-4.112 0-7.682-4.887-7.682-10.882zm6.247-.04c0 6.774 1.397 9.608 2.444 9.608 1.436 0-.582-5.05 1.009-5.05 1.591 0 1.319 4.68 2.91 4.68 1.203 0 1.901-2.71 1.901-8.13 0-8.828-1.823-11.374-4.113-11.374-2.328 0-4.151 2.546-4.151 10.265z"
      ></path>
      <path
        fill="url(#paint3_linear_3745_102)"
        d="M160.376 22.121c0-6.323 4.074-11.168 10.476-11.168 6.208 0 10.399 4.475 10.399 10.922 0 5.708-3.299 11.251-6.985 11.251-3.453 0-3.336-4.886-3.996-4.886-.698 0 1.824 4.763-2.212 4.763-4.112 0-7.682-4.887-7.682-10.882zm6.247-.04c0 6.774 1.397 9.608 2.444 9.608 1.436 0-.582-5.05 1.009-5.05 1.591 0 1.319 4.68 2.91 4.68 1.203 0 1.901-2.71 1.901-8.13 0-8.828-1.823-11.374-4.113-11.374-2.328 0-4.151 2.546-4.151 10.265z"
      ></path>
      <path
        fill="#fff"
        d="M153.57 4.999h8.769c.388 0 .621-.246.621-.657 0-.37-.233-.616-.621-.616h-20.254v-.862h20.254c.854 0 1.436.575 1.436 1.478s-.582 1.52-1.436 1.52h-4.462c-1.086 0-1.862.78-1.862 1.93v24.924h-6.441V9.228c0-2.587 1.591-4.23 3.996-4.23z"
      ></path>
      <path
        fill="url(#paint4_linear_3745_102)"
        d="M153.57 4.999h8.769c.388 0 .621-.246.621-.657 0-.37-.233-.616-.621-.616h-20.254v-.862h20.254c.854 0 1.436.575 1.436 1.478s-.582 1.52-1.436 1.52h-4.462c-1.086 0-1.862.78-1.862 1.93v24.924h-6.441V9.228c0-2.587 1.591-4.23 3.996-4.23z"
      ></path>
      <path
        fill="url(#paint5_linear_3745_102)"
        d="M153.57 4.999h8.769c.388 0 .621-.246.621-.657 0-.37-.233-.616-.621-.616h-20.254v-.862h20.254c.854 0 1.436.575 1.436 1.478s-.582 1.52-1.436 1.52h-4.462c-1.086 0-1.862.78-1.862 1.93v24.924h-6.441V9.228c0-2.587 1.591-4.23 3.996-4.23z"
      ></path>
      <path
        fill="#fff"
        d="M135.757 10.953c1.358 0 8.847.164 8.847 5.871 0 6.98-11.291 5.831-11.291 7.761 0 .452.698.41 1.28.41 1.902 0 9.468-.451 9.468 3.778 0 3.82-6.014 4.353-8.536 4.353-6.946 0-11.253-4.024-11.253-10.8 0-6.816 4.307-11.373 11.485-11.373zm-.737 1.026c-3.957 0-4.501 7.638-4.501 10.06 0 2.998.815 10.02 5.044 10.02 1.863 0 3.298-1.315 3.298-3.08 0-2.628-3.142-3.121-4.539-3.121-.893 0-1.824-.205-1.824-1.396 0-2.833 5.859-.288 5.859-6.857 0-2.259-.698-5.626-3.337-5.626z"
      ></path>
      <path
        fill="url(#paint6_linear_3745_102)"
        d="M135.757 10.953c1.358 0 8.847.164 8.847 5.871 0 6.98-11.291 5.831-11.291 7.761 0 .452.698.41 1.28.41 1.902 0 9.468-.451 9.468 3.778 0 3.82-6.014 4.353-8.536 4.353-6.946 0-11.253-4.024-11.253-10.8 0-6.816 4.307-11.373 11.485-11.373zm-.737 1.026c-3.957 0-4.501 7.638-4.501 10.06 0 2.998.815 10.02 5.044 10.02 1.863 0 3.298-1.315 3.298-3.08 0-2.628-3.142-3.121-4.539-3.121-.893 0-1.824-.205-1.824-1.396 0-2.833 5.859-.288 5.859-6.857 0-2.259-.698-5.626-3.337-5.626z"
      ></path>
      <path
        fill="url(#paint7_linear_3745_102)"
        d="M135.757 10.953c1.358 0 8.847.164 8.847 5.871 0 6.98-11.291 5.831-11.291 7.761 0 .452.698.41 1.28.41 1.902 0 9.468-.451 9.468 3.778 0 3.82-6.014 4.353-8.536 4.353-6.946 0-11.253-4.024-11.253-10.8 0-6.816 4.307-11.373 11.485-11.373zm-.737 1.026c-3.957 0-4.501 7.638-4.501 10.06 0 2.998.815 10.02 5.044 10.02 1.863 0 3.298-1.315 3.298-3.08 0-2.628-3.142-3.121-4.539-3.121-.893 0-1.824-.205-1.824-1.396 0-2.833 5.859-.288 5.859-6.857 0-2.259-.698-5.626-3.337-5.626z"
      ></path>
      <path
        fill="#fff"
        d="M103.991 2.864h6.053v8.54h1.824c10.709 0 11.446 7.186 11.446 10.964 0 3.12-.543 10.348-8.304 10.348h-.543c-.737 0-1.203-.616-1.203-1.479V27.09c0-.37-.232-.657-.62-.657-.35 0-.582.288-.582.657v4.23c0 .82-.544 1.396-1.32 1.396h-6.751V2.864zm10.088 28.373c0 .37.155.616.349.616h.039c2.173 0 2.6-5.625 2.6-9.362 0-4.64-.621-10.265-5.743-10.265h-1.28v19.627h.349c.543 0 .854-.328.854-.903v-3.86c0-.903.582-1.52 1.397-1.52.853 0 1.435.617 1.435 1.52v4.148z"
      ></path>
      <path
        fill="url(#paint8_linear_3745_102)"
        d="M103.991 2.864h6.053v8.54h1.824c10.709 0 11.446 7.186 11.446 10.964 0 3.12-.543 10.348-8.304 10.348h-.543c-.737 0-1.203-.616-1.203-1.479V27.09c0-.37-.232-.657-.62-.657-.35 0-.582.288-.582.657v4.23c0 .82-.544 1.396-1.32 1.396h-6.751V2.864zm10.088 28.373c0 .37.155.616.349.616h.039c2.173 0 2.6-5.625 2.6-9.362 0-4.64-.621-10.265-5.743-10.265h-1.28v19.627h.349c.543 0 .854-.328.854-.903v-3.86c0-.903.582-1.52 1.397-1.52.853 0 1.435.617 1.435 1.52v4.148z"
      ></path>
      <path
        fill="url(#paint9_linear_3745_102)"
        d="M103.991 2.864h6.053v8.54h1.824c10.709 0 11.446 7.186 11.446 10.964 0 3.12-.543 10.348-8.304 10.348h-.543c-.737 0-1.203-.616-1.203-1.479V27.09c0-.37-.232-.657-.62-.657-.35 0-.582.288-.582.657v4.23c0 .82-.544 1.396-1.32 1.396h-6.751V2.864zm10.088 28.373c0 .37.155.616.349.616h.039c2.173 0 2.6-5.625 2.6-9.362 0-4.64-.621-10.265-5.743-10.265h-1.28v19.627h.349c.543 0 .854-.328.854-.903v-3.86c0-.903.582-1.52 1.397-1.52.853 0 1.435.617 1.435 1.52v4.148z"
      ></path>
      <path
        fill="#fff"
        d="M81.707 22.902V11.405h6.053v11.497c0 8.459.35 8.951 2.134 8.951h.117c.194 0 .31-.246.31-.615V27.09c0-.903.582-1.519 1.436-1.519.815 0 1.397.616 1.397 1.52v3.86c0 .574.31.902.853.902h.35V11.405h6.052v21.31h-6.712c-.815 0-1.358-.574-1.358-1.395v-4.23c0-.37-.233-.657-.582-.657-.388 0-.621.288-.621.657v4.148c0 .862-.466 1.478-1.125 1.478h-.699c-7.41 0-7.605-2.094-7.605-9.814z"
      ></path>
      <path
        fill="url(#paint10_linear_3745_102)"
        d="M81.707 22.902V11.405h6.053v11.497c0 8.459.35 8.951 2.134 8.951h.117c.194 0 .31-.246.31-.615V27.09c0-.903.582-1.519 1.436-1.519.815 0 1.397.616 1.397 1.52v3.86c0 .574.31.902.853.902h.35V11.405h6.052v21.31h-6.712c-.815 0-1.358-.574-1.358-1.395v-4.23c0-.37-.233-.657-.582-.657-.388 0-.621.288-.621.657v4.148c0 .862-.466 1.478-1.125 1.478h-.699c-7.41 0-7.605-2.094-7.605-9.814z"
      ></path>
      <path
        fill="url(#paint11_linear_3745_102)"
        d="M81.707 22.902V11.405h6.053v11.497c0 8.459.35 8.951 2.134 8.951h.117c.194 0 .31-.246.31-.615V27.09c0-.903.582-1.519 1.436-1.519.815 0 1.397.616 1.397 1.52v3.86c0 .574.31.902.853.902h.35V11.405h6.052v21.31h-6.712c-.815 0-1.358-.574-1.358-1.395v-4.23c0-.37-.233-.657-.582-.657-.388 0-.621.288-.621.657v4.148c0 .862-.466 1.478-1.125 1.478h-.699c-7.41 0-7.605-2.094-7.605-9.814z"
      ></path>
      <path
        fill="#fff"
        d="M74.317 4.999h8.769c.388 0 .62-.246.62-.657 0-.37-.232-.616-.62-.616H62.832v-.862h20.254c.853 0 1.435.575 1.435 1.478s-.582 1.52-1.435 1.52h-4.462c-1.087 0-1.863.78-1.863 1.93v24.924h-6.44V9.228c0-2.587 1.59-4.23 3.996-4.23z"
      ></path>
      <path
        fill="url(#paint12_linear_3745_102)"
        d="M74.317 4.999h8.769c.388 0 .62-.246.62-.657 0-.37-.232-.616-.62-.616H62.832v-.862h20.254c.853 0 1.435.575 1.435 1.478s-.582 1.52-1.435 1.52h-4.462c-1.087 0-1.863.78-1.863 1.93v24.924h-6.44V9.228c0-2.587 1.59-4.23 3.996-4.23z"
      ></path>
      <path
        fill="url(#paint13_linear_3745_102)"
        d="M74.317 4.999h8.769c.388 0 .62-.246.62-.657 0-.37-.232-.616-.62-.616H62.832v-.862h20.254c.853 0 1.435.575 1.435 1.478s-.582 1.52-1.435 1.52h-4.462c-1.087 0-1.863.78-1.863 1.93v24.924h-6.44V9.228c0-2.587 1.59-4.23 3.996-4.23z"
      ></path>
      <path
        fill="#999"
        d="M44.733 22.902V11.405h6.053v11.497c0 8.459.349 8.951 2.134 8.951h.116c.194 0 .31-.246.31-.615V27.09c0-.903.583-1.519 1.436-1.519.815 0 1.397.616 1.397 1.52v3.86c0 .574.31.902.854.902h.349V11.405h6.053v21.31h-6.713c-.815 0-1.358-.574-1.358-1.395v-4.23c0-.37-.233-.657-.582-.657-.388 0-.62.288-.62.657v4.148c0 .862-.466 1.478-1.126 1.478h-.698c-7.411 0-7.605-2.094-7.605-9.814zM21.263 22.121c0-6.323 4.074-11.168 10.476-11.168 6.208 0 10.398 4.475 10.398 10.922 0 5.708-3.298 11.251-6.984 11.251-3.453 0-3.337-4.886-3.996-4.886-.699 0 1.823 4.763-2.212 4.763-4.113 0-7.682-4.887-7.682-10.882zm6.246-.04c0 6.774 1.397 9.608 2.445 9.608 1.436 0-.582-5.05 1.009-5.05 1.59 0 1.319 4.68 2.91 4.68 1.203 0 1.901-2.71 1.901-8.13 0-8.828-1.824-11.374-4.113-11.374-2.328 0-4.152 2.546-4.152 10.265z"
      ></path>
      <path
        fill="#fff"
        d="M15.86 22.327v10.389H9.42V24.79c0-1.027.62-2.053 1.513-2.464l2.716-1.396c.892-.41 1.784-1.355 2.172-2.3L22.03 4.178c.117-.247 0-.452-.271-.452-.272 0-.582.205-.698.452l-5.588 13.057c-.388.945-1.28 1.848-2.173 2.259l-1.086.492a3.327 3.327 0 01-1.397.329c-1.241 0-2.483-.657-2.949-1.807L.961 2.864h7.1l5.743 12.934c.233.452.465.657.698.657.233 0 .505-.205.699-.657l4.966-11.62c.35-.78 1.125-1.314 1.94-1.314s1.125.533.815 1.314L16.559 19c-.388.903-.699 2.3-.699 3.326z"
      ></path>
      <path
        fill="url(#paint14_linear_3745_102)"
        d="M15.86 22.327v10.389H9.42V24.79c0-1.027.62-2.053 1.513-2.464l2.716-1.396c.892-.41 1.784-1.355 2.172-2.3L22.03 4.178c.117-.247 0-.452-.271-.452-.272 0-.582.205-.698.452l-5.588 13.057c-.388.945-1.28 1.848-2.173 2.259l-1.086.492a3.327 3.327 0 01-1.397.329c-1.241 0-2.483-.657-2.949-1.807L.961 2.864h7.1l5.743 12.934c.233.452.465.657.698.657.233 0 .505-.205.699-.657l4.966-11.62c.35-.78 1.125-1.314 1.94-1.314s1.125.533.815 1.314L16.559 19c-.388.903-.699 2.3-.699 3.326z"
      ></path>
      <path
        fill="url(#paint15_linear_3745_102)"
        d="M15.86 22.327v10.389H9.42V24.79c0-1.027.62-2.053 1.513-2.464l2.716-1.396c.892-.41 1.784-1.355 2.172-2.3L22.03 4.178c.117-.247 0-.452-.271-.452-.272 0-.582.205-.698.452l-5.588 13.057c-.388.945-1.28 1.848-2.173 2.259l-1.086.492a3.327 3.327 0 01-1.397.329c-1.241 0-2.483-.657-2.949-1.807L.961 2.864h7.1l5.743 12.934c.233.452.465.657.698.657.233 0 .505-.205.699-.657l4.966-11.62c.35-.78 1.125-1.314 1.94-1.314s1.125.533.815 1.314L16.559 19c-.388.903-.699 2.3-.699 3.326z"
      ></path>
      <path
        fill="#fff"
        d="M227.809 27.865c-1.493 0-2.633-.56-3.421-1.683-.788-1.134-1.181-2.746-1.181-4.835 0-2.113.393-3.719 1.181-4.817.788-1.098 1.928-1.647 3.421-1.647 1.504 0 2.644.549 3.42 1.647.788 1.098 1.182 2.698 1.182 4.8 0 2.1-.394 3.718-1.182 4.852-.788 1.122-1.928 1.683-3.42 1.683zm0-1.88c.811 0 1.408-.37 1.79-1.11.394-.752.591-1.934.591-3.546 0-1.6-.197-2.758-.591-3.474-.382-.728-.979-1.092-1.79-1.092-.8 0-1.397.364-1.791 1.092-.394.716-.591 1.874-.591 3.474 0 1.612.197 2.794.591 3.546.394.74.991 1.11 1.791 1.11zM213.132 27.686v-1.701l4.083-4.351c.549-.597.943-1.123 1.182-1.576.239-.466.358-.937.358-1.415 0-1.218-.734-1.826-2.203-1.826-1.146 0-2.202.43-3.169 1.289l-.77-1.72c.501-.453 1.128-.817 1.88-1.092a6.789 6.789 0 012.346-.411c1.349 0 2.381.304 3.098.913.728.609 1.092 1.48 1.092 2.614 0 .764-.185 1.498-.555 2.203-.358.704-.925 1.462-1.701 2.274l-2.776 2.9h5.516v1.9h-8.381z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3745_102"
          x1="-2.508"
          x2="203.486"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_3745_102"
          x1="-2.508"
          x2="203.486"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_3745_102"
          x1="-1.553"
          x2="204.441"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_3745_102"
          x1="-1.553"
          x2="204.441"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_3745_102"
          x1="-0.599"
          x2="205.395"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_3745_102"
          x1="-0.599"
          x2="205.395"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_3745_102"
          x1="-0.599"
          x2="205.395"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_3745_102"
          x1="-0.599"
          x2="205.395"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_3745_102"
          x1="0.356"
          x2="206.35"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_3745_102"
          x1="0.356"
          x2="206.35"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_3745_102"
          x1="0.356"
          x2="206.35"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_3745_102"
          x1="0.356"
          x2="206.35"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_3745_102"
          x1="1.31"
          x2="207.304"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_3745_102"
          x1="1.31"
          x2="207.304"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_3745_102"
          x1="1.31"
          x2="207.304"
          y1="21.89"
          y2="21.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F41B3B"></stop>
          <stop offset="1" stopColor="#1884F7"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_3745_102"
          x1="1.31"
          x2="207.304"
          y1="22.185"
          y2="22.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#999"></stop>
          <stop offset="1" stopColor="#999"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Youtube20;
