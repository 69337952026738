import React from "react";

function DjangoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <g fill="#2AA473" clipPath="url(#clip0_3851_84)">
        <path d="M22.142 1.5h7.492v34.456c-3.838.729-6.663 1.015-9.72 1.015C10.764 36.961 6 32.86 6 24.981c0-7.59 5.053-12.517 12.882-12.517 1.215 0 2.141.096 3.26.384V1.5zm.262 17.557c-.877-.287-1.6-.383-2.526-.383-3.79 0-5.979 2.32-5.979 6.384 0 3.957 2.093 6.142 5.93 6.142.83 0 1.505-.047 2.575-.19V19.057z"></path>
        <path d="M41.886 13.39V30.64c0 5.942-.444 8.799-1.746 11.262-1.215 2.368-2.815 3.861-6.123 5.51l-6.952-3.287c3.308-1.542 4.908-2.905 5.93-4.984 1.07-2.127 1.408-4.59 1.408-11.07V13.39h7.483zM33.648 1.5h7.492v7.639h-7.492V1.5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_3851_84">
          <path
            fill="#fff"
            d="M0 0H36V46H0z"
            transform="translate(6 1.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DjangoIcon;
