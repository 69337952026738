
import React, { useState, useEffect, useRef } from "react";
import About from "../SideBarIcons/About";
import Contact from "../SideBarIcons/Contact";
import Experience from "../SideBarIcons/Experience";
import Home from "../SideBarIcons/Home";
import Projects from "../SideBarIcons/Projects";
import Skills from "../SideBarIcons/Skills";
import Testimonials from "../SideBarIcons/Testimonials";
import Work from "../SideBarIcons/Work";
import { Link } from "react-scroll";

const navItems = [
  { name: "Home", href: "#home", Icon: Home },
  { name: "Work", href: "#work", Icon: Work },
  { name: "Testimonials", href: "#testimonials", Icon: Testimonials },
  { name: "Experience", href: "#experience", Icon: Experience },
  { name: "Projects", href: "#projects", Icon: Projects },
  { name: "Skills", href: "#skills", Icon: Skills },
  { name: "About", href: "#about", Icon: About },
  { name: "Contact", href: "#contact", Icon: Contact },
];

const Navbar = () => {
  const [selectedItem, setSelectedItem] = useState("Home");
  const scrollRef = useRef(0); 


  const handleScroll = () => {
    scrollRef.current = window.scrollY;


    const sections = navItems.map((item) => document.querySelector(item.href));
    const selected = sections.find(
      (section) =>
        section &&
        section.offsetTop <= scrollRef.current + 100 &&
        section.offsetTop + section.offsetHeight > scrollRef.current + 100
    );


    if (selected) {
      const selectedNavItem = navItems.find((item) => `#${selected.id}` === item.href);
      if (selectedNavItem && selectedItem !== selectedNavItem.name) {
        setSelectedItem(selectedNavItem.name);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleItemClick = (name) => {
    setSelectedItem(name);

    const section = document.querySelector(`#${name.toLowerCase()}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
    <div className="h-screen overflow-hidden border-r text-[#808080] border-[#292929] min-w-60 xl:min-w-72 z-50 bg-background hidden xl:block">
      <div className="p-10 border-b border-[#292929]">
        <p className="text-2xl 3xl:text-3xl text-white hover:cursor-pointer hover:text-[#FBA310]">
          Mujahid Munir
        </p>
        <p className="3xl:text-xl">Back-end Engineer</p>
      </div>
      <ul>
        {navItems.map((item, index) => (
          <Link
          activeClass="actiive"
            key={index}
            smooth
            spy
            to={item.href}
            className={`border-b border-[#292929] block items-center hover:cursor-pointer hover:bg-[#292929]/15 group ml-3 font-bold text-current group-hover:text-white scroll-smooth ${
              selectedItem === item.name ? "text-white" : "hover:text-white"
            }`}
          >
            <li
              className={`p-5 pl-10 flex items-center my-3 3xl:text-lg ${
                selectedItem === item.name ? "text-white" : ""
              }`}
              onClick={() => handleItemClick(item.name)}
            >
              <div className={` mr-5 ${
                  selectedItem === item.name ? "filter invert brightness-0" : "group-hover:filter group-hover:invert group-hover:brightness-0"
                }`}
              >  
              <item.Icon className={`text-current group-hover:text-white`} />
              </div>
              {item.name}
            </li>
          </Link>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default Navbar;
