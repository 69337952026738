import React from "react";

function Skill1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path
        fill="url(#paint0_linear_3851_22)"
        d="M23.828.625C11.642.625 12.402 5.91 12.402 5.91l.015 5.475h11.628v1.642H7.795S0 12.142 0 24.44C0 36.733 6.806 36.3 6.806 36.3h4.061v-5.707s-.219-6.805 6.698-6.805h11.532s6.48.105 6.48-6.263V6.996s.984-6.37-11.75-6.37zm-6.413 3.681a2.091 2.091 0 110 4.185A2.09 2.09 0 0115.323 6.4c0-1.157.936-2.093 2.092-2.093z"
      ></path>
      <path
        fill="url(#paint1_linear_3851_22)"
        d="M24.173 48.375c12.186 0 11.425-5.285 11.425-5.285l-.015-5.475H23.955v-1.642h16.248S48 36.858 48 24.563c0-12.296-6.805-11.86-6.805-11.86h-4.062v5.705s.219 6.805-6.698 6.805H18.903s-6.48-.105-6.48 6.263v10.528s-.984 6.37 11.75 6.37zm6.412-3.681a2.09 2.09 0 01-2.092-2.093c0-1.155.936-2.09 2.092-2.09a2.09 2.09 0 012.093 2.09 2.09 2.09 0 01-2.093 2.093z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3851_22"
          x1="4.612"
          x2="28.347"
          y1="4.798"
          y2="28.612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#387EB8"></stop>
          <stop offset="1" stopColor="#366994"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_3851_22"
          x1="19.214"
          x2="44.705"
          y1="19.948"
          y2="44.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE052"></stop>
          <stop offset="1" stopColor="#FFC331"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Skill1;
