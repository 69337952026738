import React from "react";

function MachineLearningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <path
        fill="url(#paint0_linear_3851_273)"
        fillRule="evenodd"
        d="M8 5.833A2.667 2.667 0 005.333 8.5v5.333a2.667 2.667 0 11-5.333 0V8.5a8 8 0 018-8h5.333a2.667 2.667 0 110 5.333H8zm24-2.666A2.667 2.667 0 0134.667.5H40a8 8 0 018 8v5.333a2.667 2.667 0 11-5.333 0V8.5A2.667 2.667 0 0040 5.833h-5.333A2.667 2.667 0 0132 3.167zM2.667 32.5a2.667 2.667 0 012.666 2.667V40.5A2.667 2.667 0 008 43.167h5.333a2.667 2.667 0 110 5.333H8a8 8 0 01-8-8v-5.333A2.667 2.667 0 012.667 32.5zm42.666 0A2.667 2.667 0 0148 35.167V40.5a8 8 0 01-8 8h-5.333a2.667 2.667 0 110-5.333H40a2.667 2.667 0 002.667-2.667v-5.333a2.667 2.667 0 012.666-2.667z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint1_linear_3851_273)"
        fillRule="evenodd"
        d="M19.8 14.9a3 3 0 00-3 3v1.833c.198-.022.399-.033.6-.033h.6a1.2 1.2 0 010 2.4h-.6a3 3 0 000 6 1.2 1.2 0 010 2.4c-.201 0-.402-.011-.6-.033v.633a3 3 0 006 0v-1.2a3 3 0 00-3-3 1.2 1.2 0 010-2.4 5.4 5.4 0 013 .91V17.9a3 3 0 00-3-3zm4.2-.394a5.396 5.396 0 00-4.2-2.006 5.4 5.4 0 00-5.4 5.4v2.71a5.4 5.4 0 000 8.98v1.51a5.4 5.4 0 009.6 3.394 5.396 5.396 0 004.2 2.006 5.4 5.4 0 005.4-5.4v-1.51a5.4 5.4 0 000-8.98V17.9a5.4 5.4 0 00-9.6-3.394zm7.2 5.227V17.9a3 3 0 00-6 0v7.51a5.4 5.4 0 013-.91 1.2 1.2 0 010 2.4 3 3 0 00-3 3v1.2a3 3 0 006 0v-.633a5.417 5.417 0 01-.6.033 1.2 1.2 0 010-2.4 3 3 0 000-6H30a1.2 1.2 0 010-2.4h.6c.201 0 .402.011.6.033z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3851_273"
          x1="0"
          x2="48"
          y1="24.505"
          y2="24.505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89D981"></stop>
          <stop offset="0.5" stopColor="#CEC45A"></stop>
          <stop offset="1" stopColor="#F9C63F"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_3851_273"
          x1="12"
          x2="36"
          y1="24.502"
          y2="24.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89D981"></stop>
          <stop offset="0.5" stopColor="#CEC45A"></stop>
          <stop offset="1" stopColor="#F9C63F"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MachineLearningIcon;
