import React from "react";

function Instagaram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#999"
        fillRule="evenodd"
        d="M13 7s2.485 0 4.243 1.757c0 0 1.757 1.758 1.757 4.243 0 0 0 2.485-1.757 4.243 0 0-1.758 1.757-4.243 1.757 0 0-2.485 0-4.243-1.757C8.757 17.243 7 15.485 7 13c0 0 0-2.485 1.757-4.243C8.757 8.757 10.515 7 13 7zm0 2s-1.657 0-2.828 1.172c0 0-1.172 1.171-1.172 2.828 0 0 0 1.657 1.172 2.828 0 0 1.171 1.172 2.828 1.172 0 0 1.657 0 2.828-1.172 0 0 1.172-1.171 1.172-2.828 0 0 0-1.657-1.172-2.828 0 0-1.171-1.172-2.828-1.172z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#999"
        fillRule="evenodd"
        d="M.5 7.5s0-2.9 2.05-4.95C2.55 2.55 4.6.5 7.5.5h11s2.9 0 4.95 2.05c0 0 2.05 2.05 2.05 4.95v11s0 2.9-2.05 4.95c0 0-2.05 2.05-4.95 2.05h-11s-2.9 0-4.95-2.05c0 0-2.05-2.05-2.05-4.95v-11zm2 0v11s0 2.071 1.464 3.535c0 0 1.465 1.465 3.536 1.465h11s2.071 0 3.535-1.465c0 0 1.465-1.464 1.465-3.535v-11s0-2.071-1.465-3.536c0 0-1.464-1.464-3.535-1.464h-11s-2.071 0-3.536 1.464c0 0-1.464 1.465-1.464 3.536z"
        clipRule="evenodd"
      ></path>
      <path fill="#999" d="M21 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"></path>
    </svg>
  );
}

export default Instagaram;
